import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import {
  provideRouter,
  withViewTransitions,
  withRouterConfig,
} from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { JwtInterceptor } from './common/interceptors/jwt.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HAMMER_LOADER, HammerModule, provideClientHydration, withEventReplay } from '@angular/platform-browser';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes, 
      withViewTransitions({ skipInitialTransition: true })
    ),
    provideHttpClient(withInterceptors([JwtInterceptor]),withFetch()),
    provideAnimations(),
    importProvidersFrom(
      HammerModule
    ),
    { provide: HAMMER_LOADER, useValue: () => import('hammerjs') }, provideClientHydration(withEventReplay()),
  ]
};
