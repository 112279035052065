import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AuthService } from './auth/services/auth.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-root',
    imports: [
        RouterOutlet,
        ToastModule,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.css',
    providers: [
        MessageService,
        ConfirmationService
    ]
})
export class AppComponent implements OnInit {

  authService = inject(AuthService);
  platformId = inject(PLATFORM_ID)
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
    this.authService.checkAuthStatus();
    }
  }

}
